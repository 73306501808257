<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        @click.native="$router.push('/')"
      >
        <div class="site-navbar-logo-box">
          <img
            :src="Logo"
            style="max-width:100px"
          >
          <h4 style="cursor:pointer">
            Mortgage <span class="logo-text">Envy</span> Corporation
          </h4>
        </div>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->

      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Real Estate Licensee
          </b-card-title>
          <b-card-text class="mb-2">
            Registration
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">

              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    label="First Name"
                    label-for="register-firstname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      rules="required"
                    >
                      <b-form-input
                        id="register-firstname"
                        v-model="first_name"
                        name="register-firstname"
                        :state="errors.length > 0 ? false:null"
                        placeholder="John"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group
                    label="Last Name"
                    label-for="register-lastname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      rules="required"
                    >
                      <b-form-input
                        id="register-lastname"
                        v-model="last_name"
                        name="register-lastname"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Doe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <!-- first name -->

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- phone number -->
              <b-form-group
                label="Phone Number"
                label-for="register-phone-number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone Number"
                  rules="required"
                >
                  <b-form-input
                    id="register-phone-number"
                    v-model="phone_number"
                    name="register-phone-number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="000 000 0000"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- end of phone number -->

              <!-- Broker Details starts here -->
              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    label="License Type"
                    label-for="register-license_type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="License Type"
                      rules="required"
                    >
                      <b-form-select
                        id="register-license_type"
                        v-model="license_type"
                        name="register-license_type"
                        :state="errors.length > 0 ? false:null"
                        :options="options"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    label="Brokerage (Company)"
                    label-for="register-brokerage"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Brokerage"
                      rules="required"
                    >
                      <b-form-input
                        id="register-brokerage"
                        v-model="brokerage"
                        name="register-brokerage"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Brokerage name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    label="Supervising Broker"
                    label-for="register-broker"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Broker"
                      rules="required"
                    >
                      <b-form-input
                        id="register-broker"
                        v-model="broker"
                        name="register-broker"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Broker name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    label="Supervising Broker Phone Number"
                    label-for="register-broker_phone_number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Broker Phone Number"
                      rules="required"
                    >
                      <b-form-input
                        id="register-broker_phone_number"
                        v-model="broker_phone_number"
                        name="register-broker_phone_number"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Broker phone number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <!-- Broker Details ends here -->
              <!-- password -->

              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label-for="register-confirm"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-confirm"
                      v-model="confirm_password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link
                    to="/site/privacy-policy"
                    target="_blank"
                  >
                    privacy policy
                  </b-link>
                  &
                  <b-link
                    to="/site/terms-of-use"
                    target="_blank"
                  >
                    terms
                  </b-link>
                </b-form-checkbox>
              </b-form-group>

              <div class="row">
                <div class="col-12">
                  <b-button
                    :disabled="loading"
                    variant="primary"
                    class="float-right"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    <b-spinner
                      v-if="loading"
                      small
                      type="grow"
                    />
                    {{ loading?'Loading...':'Register' }}
                  </b-button>
                </div>
              </div>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;Login here</span>
            </b-link>
          </p>
        </b-col>
      </b-col>

      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BSpinner, BFormSelect, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import Logo from '@/assets/log2.png'
import showToast from '@/mixins/showToast'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BFormSelect,
  },
  mixins: [togglePasswordVisibility, showToast],
  data() {
    return {
      Logo,
      loading: false,
      status: false,
      first_name: '',
      last_name: '',
      userEmail: '',
      password: '',
      phone_number: '',
      confirm_password: '',
      vendor_name: '',
      brokerage: '',
      broker: '',
      broker_phone_number: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      license_type: null,
      options: [
        { value: null, text: 'Please select license Type' },
        { value: 'Real Estate Broker', text: 'Real Estate Broker' },
        { value: 'Real Estate Salesperson', text: 'Real Estate Salesperson' },
        { value: 'Real Estate Associate Broker', text: 'Real Estate Associate Broker' },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    register() {
      if (!this.status) {
        return this.showError('Please accept our terms and conditions')
      }
      this.loading = true
      this.$http
        .post('/api/auth/agents/register',
          {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.userEmail,
            password: this.password,
            password_confirmation: this.confirm_password,
            phone_number: this.phone_number,
            licensee_type: this.license_type,
            brokerage_firm: this.brokerage,
            broker_name: this.broker,
            broker_phone: this.broker_phone_number,
          })
        .then(res => {
          this.loading = false
          this.showSuccess('Registered Successfully')
          this.$router.push({ name: 'login' })
        })
        .catch(err => {
          this.loading = false
          this.showError('Something went wrong, please try again')
        })
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.register()
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
